@import 'config/variables';
@import 'vendors/include-media';
@import 'components/button/mixin';
@import 'components/field/mixin';

$repertus_search-button-type: 'primary' !default;
$repertus_search-icon-pos: 'no-icon' !default;

// Style Repertus Search Button
.repertus_packstation--search-button,
.repertus_packstation_search_button .action-basic,
#repertus_find_packstation_button_execute {
    @include button(
        $type: $repertus_search-button-type,
        $icon_pos: $repertus_search-icon-pos
    );
    margin: 1rem 0 0;
}

// Style Repertus Search Modal
.repertus-packstation-search {
    $root: &;

    &.modal-popup._show {
        // If any other Magento's modal is opened, Repertus popup must be displayed on top of it
        z-index: 905 !important;
    }

    .modal-title {
        padding-left: 3rem;
        text-transform: none;
    }

    .modal-content {
        padding-bottom: 3rem;
    }

    .modal-footer {
        display: none;
    }

    .action-close {
        right: 1rem;
        cursor: pointer;
    }

    .tonur_packstation_input {
        margin-bottom: 1.5rem;
    }

    .repertus_packstaion--search-modal--input-fields {
        display: flex;
        justify-content: space-between;
    }

    .repertus--input-field {
        @include field();

        label {
            @include field-label();
        }

        input {
            @include field-input();
        }

        &.postcode {
            margin-right: 1.5rem;
        }
    }

    #tonur_packstation_map {
        position: relative;
        min-height: 27rem;
        max-height: 40rem;
        border: 0.1rem solid $color_white;

        @include media('>=phone') {
            min-height: 29.5rem;
        }

        @include media('>=tablet') {
            min-height: 40rem;
        }
    }

    #tonur_packstation_map_overlay {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 40.2rem;
        background-color: $color_black;
        opacity: 0.7;
        z-index: 100;

        p {
            margin-top: 27%;
            color: $color_white;
            text-align: center;
        }
    }

    #tonur_packstation_map_frame {
        position: relative;

        &.busy {
            #tonur_packstation_map_overlay {
                display: table-cell;
            }
        }
    }

    .MicrosoftMap .Infobox {
        .infobox-body {
            min-width: 190px;
        }

        .infobox-title {
            padding: 1rem 1rem 0;
        }

        .infobox-actions {
            height: 2.5rem;
        }
    }
}

// Style Repertus Checkout Fields
.repertus_address_type_fieldset {
    &,
    .fieldset-wrapper-content {
        width: 100%;
    }
}

.field[name='shippingAddress.repertus_address_type'] {
    display: none;
}
