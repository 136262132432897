/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-form, .cs-form__fieldset {
  border: 0;
  padding: 0;
  margin: 0; }

.cs-form__field + .cs-form__field {
  margin-top: 1em; }

.cs-form--disabled {
  opacity: 0.6; }

.cs-form__button {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  position: relative;
  line-height: 1.275em;
  margin: 0;
  text-transform: none;
  padding: 0.75em 1em;
  box-sizing: border-box;
  transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
  vertical-align: middle;
  z-index: 5;
  will-change: transform, background-color, border-color, fill, color; }
  .cs-form__button:hover, .cs-form__button:focus, .cs-form__button:active {
    background-color: #324dcf !important; }
  .cs-form__button:before, .cs-form__button:after {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    right: -1px; }
  .cs-form__button:before {
    width: 5.5rem;
    border-radius: 0 3px 3px 0;
    z-index: -1;
    background: linear-gradient(100deg, transparent 0%, transparent 15%, #142b9e 15%, #142b9e 100%); }
  .cs-form__button:after {
    width: 4rem;
    min-width: auto;
    background-color: #142b9e;
    transition: width 0.25s linear;
    z-index: -2;
    border-radius: 3px; }
  .cs-form__button:hover:after {
    width: calc(100% + 2px); }
  .cs-form__button::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0; }
  .cs-form__button, .cs-form__button:visited {
    cursor: pointer;
    border-radius: 3px;
    outline-offset: 1px;
    text-decoration: none;
    box-shadow: none;
    border: 1px solid transparent;
    color: #fff;
    background-color: #324dcf; }
    .cs-form__button *, .cs-form__button:visited * {
      color: #fff;
      fill: #fff; }
  .cs-form__button:hover:not([disabled]), .cs-form__button:focus:not([disabled]), .cs-form__button:active:not([disabled]) {
    box-shadow: none;
    outline: none;
    text-decoration: none;
    border: 1px solid transparent;
    color: #fff;
    background-color: #142b9e; }
    .cs-form__button:hover:not([disabled]) *, .cs-form__button:focus:not([disabled]) *, .cs-form__button:active:not([disabled]) * {
      color: #fff;
      fill: #fff; }
  .cs-form__button[disabled] {
    opacity: 0.65;
    cursor: auto; }

.cs-form__button-span {
  margin-top: 0;
  z-index: 1;
  font-weight: 700;
  vertical-align: baseline;
  display: inline-block;
  line-height: 1.275em;
  letter-spacing: normal;
  padding-right: 4em; }

.cs-form__button-icon {
  max-width: 1em;
  max-height: 1.4rem;
  z-index: 2;
  height: auto;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  margin-right: 0;
  right: 1em; }

.form,
.form .fieldset {
  border: 0;
  padding: 0;
  margin: 0; }

.form .field + .field {
  margin-top: 1em; }
