@import 'config/variables';
@import 'components/button/mixin';

$form_button-type: $button_default-type !default;
$form_button-icon-pos: $button_default-icon-pos !default;

.#{$ns}form {
    &,
    &__fieldset {
        border: 0;
        padding: 0;
        margin: 0;
    }

    &__field {
        & + & {
            margin-top: 1em;
        }
    }

    &--disabled {
        opacity: 0.6;
    }

    &__button {
        @include button(
            $type: $form_button-type,
            $icon_pos: $form_button-icon-pos
        );
    }

    &__button-span {
        @include button_span(
            $type: $form_button-type,
            $icon_pos: $form_button-icon-pos
        );
    }

    &__button-icon {
        @include button_icon(
            $type: $form_button-type,
            $icon_pos: $form_button-icon-pos
        );
    }
}

.form {
    &,
    .fieldset {
        border: 0;
        padding: 0;
        margin: 0;
    }

    .field {
        + .field {
            margin-top: 1em;
        }
    }
}
