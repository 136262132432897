/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
html {
  box-sizing: border-box;
  font-size: 62.5%;
  background: #fff;
  /* stylelint-disable */
  overflow-x: hidden;
  /* stylelint-enable */ }

*,
*:before,
*:after {
  box-sizing: inherit; }

body {
  font-family: "Libre Franklin", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Oxygen, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-overflow-scrolling: touch;
  margin: 0;
  font-size: 1.4rem;
  font-display: swap; }

button,
input,
textarea,
select {
  font-family: inherit; }

/* Hide HTML5 Up and Down arrows. */
input[type='number'] {
  appearance: textfield; }
  input[type='number']::-webkit-outer-spin-button, input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
