@import 'config/variables';
@import 'vendors/include-media';

$password-strength_icon-width: 2rem !default;
$password-strength_icon-height: 0.7rem !default;
$password-strength_icons-space: 0.5rem !default;

$password-strength_icon-width-small-mobile: 1.5rem !default;
$password-strength_icon-height-small-mobile: 0.5rem !default;
$password-strength_icons-space-small-mobile: 0.2rem !default;

$password-strength_background-color: initial !default;
$password-strength_border: none !default;

$password-strength_combined-icon-width: $password-strength_icon-width +
    $password-strength_icons-space;
$password-strength_combined-icon-width-small-mobile: $password-strength_icon-width-small-mobile +
    $password-strength_icons-space-small-mobile;

// Original Magento class is left for this component
//
.password-strength-meter {
    font-size: 1.2rem;
    background-color: $password-strength_background-color;
    border: $password-strength_border;
    border-top-width: 0;

    @include media('<phoneLg') {
        font-size: 1rem;
        padding: 0 0.5rem;
    }

    &:before {
        display: none;
    }

    background-size: $password-strength_icon-width
        $password-strength_icon-height;
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: calc(100% - 1rem),
        calc(100% - (#{$password-strength_combined-icon-width} + 1rem)),
        calc(100% - (#{$password-strength_combined-icon-width} * 2 + 1rem)),
        calc(100% - (#{$password-strength_combined-icon-width} * 3 + 1rem)),
        calc(100% - (#{$password-strength_combined-icon-width} * 4 + 1rem)),
        calc(100% - (#{$password-strength_combined-icon-width} * 5 + 1rem));
    background-image: url('../images/icons/forms/icon-password-strength-meter.svg'),
        url('../images/icons/forms/icon-password-strength-meter.svg'),
        url('../images/icons/forms/icon-password-strength-meter.svg'),
        url('../images/icons/forms/icon-password-strength-meter.svg'),
        url('../images/icons/forms/icon-password-strength-meter.svg'),
        url('../images/icons/forms/icon-password-strength-meter.svg');

    .password-weak & {
        background-image: url('../images/icons/forms/icon-password-strength-meter.svg'),
            url('../images/icons/forms/icon-password-strength-meter.svg'),
            url('../images/icons/forms/icon-password-strength-meter.svg'),
            url('../images/icons/forms/icon-password-strength-meter.svg'),
            url('../images/icons/forms/icon-password-strength-meter-weak.svg'),
            url('../images/icons/forms/icon-password-strength-meter-weak.svg');
    }

    .password-medium & {
        background-image: url('../images/icons/forms/icon-password-strength-meter.svg'),
            url('../images/icons/forms/icon-password-strength-meter.svg'),
            url('../images/icons/forms/icon-password-strength-meter.svg'),
            url('../images/icons/forms/icon-password-strength-meter-medium.svg'),
            url('../images/icons/forms/icon-password-strength-meter-medium.svg'),
            url('../images/icons/forms/icon-password-strength-meter-medium.svg');
    }

    .password-strong & {
        background-image: url('../images/icons/forms/icon-password-strength-meter.svg'),
            url('../images/icons/forms/icon-password-strength-meter-strong.svg'),
            url('../images/icons/forms/icon-password-strength-meter-strong.svg'),
            url('../images/icons/forms/icon-password-strength-meter-strong.svg'),
            url('../images/icons/forms/icon-password-strength-meter-strong.svg'),
            url('../images/icons/forms/icon-password-strength-meter-strong.svg');
    }

    .password-very-strong & {
        background-image: url('../images/icons/forms/icon-password-strength-meter-very-strong.svg'),
            url('../images/icons/forms/icon-password-strength-meter-very-strong.svg'),
            url('../images/icons/forms/icon-password-strength-meter-very-strong.svg'),
            url('../images/icons/forms/icon-password-strength-meter-very-strong.svg'),
            url('../images/icons/forms/icon-password-strength-meter-very-strong.svg'),
            url('../images/icons/forms/icon-password-strength-meter-very-strong.svg');
    }

    @include media('<phoneLg') {
        background-size: $password-strength_icon-width-small-mobile
            $password-strength_icon-height-small-mobile;
        background-position-x: calc(100% - 1rem),
            calc(
                100% -
                    (
                        #{$password-strength_combined-icon-width-small-mobile} +
                            1rem
                    )
            ),
            calc(
                100% -
                    (
                        #{$password-strength_combined-icon-width-small-mobile} *
                            2 + 1rem
                    )
            ),
            calc(
                100% -
                    (
                        #{$password-strength_combined-icon-width-small-mobile} *
                            3 + 1rem
                    )
            ),
            calc(
                100% -
                    (
                        #{$password-strength_combined-icon-width-small-mobile} *
                            4 + 1rem
                    )
            ),
            calc(
                100% -
                    (
                        #{$password-strength_combined-icon-width-small-mobile} *
                            5 + 1rem
                    )
            );
    }
}
