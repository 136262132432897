/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-offcanvas {
  position: relative;
  z-index: 300; }
  .cs-offcanvas__drawer, .cs-offcanvas__overlay {
    position: fixed;
    left: 0;
    top: 5rem; }
    @media (min-width: 48em) {
      .cs-offcanvas__drawer, .cs-offcanvas__overlay {
        top: 8.3rem; } }
    @media (min-width: 64em) {
      .cs-offcanvas__drawer, .cs-offcanvas__overlay {
        display: none; } }
  .cs-offcanvas__overlay {
    width: 100%;
    height: 100%;
    background-color: transparent;
    transition: background-color 0.3s, transform 1ms 0.3s;
    transform: scale(0);
    cursor: pointer; }
    .cs-offcanvas__overlay--visible {
      transform: scale(1);
      background: rgba(0, 0, 0, 0.7);
      transition: background-color 0.3s; }
  .cs-offcanvas__drawer {
    position: fixed;
    bottom: 0;
    background: #fff;
    transition: transform 0.3s;
    width: 90%;
    max-width: 40rem; }
    .cs-offcanvas--side_left .cs-offcanvas__drawer {
      left: 0;
      right: auto;
      transform: translate3d(-105%, 0, 0); }
    .cs-offcanvas--side_right .cs-offcanvas__drawer {
      left: auto;
      right: 0;
      transform: translate3d(105%, 0, 0); }
    .cs-offcanvas__drawer--visible {
      transform: translate3d(0, 0, 0) !important; }
  .cs-offcanvas__spinner {
    position: absolute;
    width: 6rem;
    height: 6rem;
    left: 50%;
    top: 20%;
    margin-left: -3rem; }
