/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* Radio and checkbox styling */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-header-search {
  width: 100%; }
  @media (min-width: 64em) {
    .cs-header-search {
      width: 50%; } }
  .cs-header-search__field {
    position: relative; }
    .cs-header-search__field:before, .cs-header-search__field:after {
      content: '';
      position: absolute;
      opacity: 0;
      transition: opacity 0.3s;
      left: -0.5rem;
      right: -0.5rem;
      background: #fff; }
    .cs-header-search__field:before {
      content: '';
      position: absolute;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
      top: -0.5rem;
      bottom: -0.5rem;
      border-radius: 5px 5px 0 0; }
    .cs-header-search__field:after {
      content: '';
      position: absolute;
      bottom: -1rem;
      top: 100%;
      z-index: 209; }
    .active-popup .cs-header-search__field:before, .active-popup .cs-header-search__field:after {
      opacity: 1; }
  .cs-header-search__input {
    font-size: 1em;
    line-height: 1.275em;
    padding: 0.75em 1em;
    color: #212121;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #dcdcdc;
    box-shadow: none;
    transition: border-color 0.5s;
    margin: 0 0 0.2em;
    width: 100%;
    background-clip: padding-box;
    font-family: inherit;
    font-size: 1.4rem;
    position: relative;
    z-index: 210;
    margin: 0;
    -webkit-appearance: none; }
    .cs-header-search__input:hover:not([disabled]), .cs-header-search__input:focus:not([disabled]) {
      border: 1px solid #324dcf;
      box-shadow: none; }
    .cs-header-search__input::placeholder {
      opacity: 1;
      color: #888; }
    .cs-header-search__input[disabled] {
      opacity: 0.65; }
    .ajax-loading .cs-header-search__input:focus + .cs-header-search__action .cs-header-search__button-container--search {
      transform: translateX(100%);
      opacity: 0; }
    .ajax-loading .cs-header-search__input:focus + .cs-header-search__action .cs-header-search__button-container--loader {
      transform: translateX(0);
      opacity: 1; }
  .cs-header-search__action {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 220;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  .cs-header-search__close-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
    display: block;
    opacity: 0.6;
    transition: opacity 0.3s linear; }
    .cs-header-search__close-icon:hover {
      cursor: pointer;
      opacity: 1; }
    @media (min-width: 64em) {
      .cs-header-search__close-icon {
        display: none; } }
    .cs-header-search__close-icon path {
      fill: #888; }
  .cs-header-search__close-label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    /* 1 */ }
  .cs-header-search__button-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: transform 0.5s cubic-bezier(0, 0.66, 0.32, 0.99), opacity 0.15s linear; }
    .cs-header-search__button-container--search {
      transform: translateX(0); }
    .cs-header-search__button-container--loader {
      transform: translateX(100%); }
  .cs-header-search__button {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    will-change: transform, background-color, border-color, fill, color;
    width: 5rem;
    height: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 0 !important;
    backface-visibility: hidden;
    transform: skew(-18deg) translateX(0.7rem); }
    .cs-header-search__button::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-header-search__button, .cs-header-search__button:visited {
      cursor: pointer;
      border-radius: 3px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #324dcf; }
      .cs-header-search__button *, .cs-header-search__button:visited * {
        color: #fff;
        fill: #fff; }
    .cs-header-search__button:hover:not([disabled]), .cs-header-search__button:focus:not([disabled]), .cs-header-search__button:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #142b9e; }
      .cs-header-search__button:hover:not([disabled]) *, .cs-header-search__button:focus:not([disabled]) *, .cs-header-search__button:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .cs-header-search__button[disabled] {
      opacity: 0.65;
      cursor: auto; }
    .cs-header-search__button[disabled] {
      opacity: 1; }
  .cs-header-search__button-icon {
    transform: skew(18deg) translateX(-0.3rem); }
    .cs-header-search__button-icon--search {
      width: 2rem;
      height: 2rem; }
      .cs-header-search__button-icon--search path {
        fill: #fff; }
    .cs-header-search__button-icon--loader {
      stroke: #fff;
      width: 3.2rem;
      height: 3.2rem; }
  .cs-header-search__button-label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    /* 1 */ }
  .cs-header-search__autocomplete {
    width: calc(100% - 1rem);
    left: 0.5rem;
    right: 0.5rem; }
    @media (max-width: 47.99em) {
      .cs-header-search__autocomplete {
        max-height: calc( 100vh - 5rem - 1.275em - 7rem);
        overflow-y: auto; } }
    @media (min-width: 64em) {
      .cs-header-search__autocomplete {
        margin-top: 0.5rem;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.17);
        border: 0;
        width: 100%; } }
