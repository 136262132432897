/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-minicart__close {
  display: inline-block;
  background: none;
  box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  position: absolute;
  right: -1.5rem;
  width: 5rem;
  height: 3rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer; }
  .cs-minicart__close:focus, .cs-minicart__close:active, .cs-minicart__close:hover {
    background: transparent;
    border: none;
    color: inherit; }
  .cs-minicart__close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 5rem;
    line-height: 3rem;
    color: inherit;
    content: '\e616';
    /* stylelint-disable-next-line */
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }

.cs-minicart__close-text {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.cs-minicart__title-wrapper {
  position: relative;
  padding: 2rem 0;
  display: flex;
  color: #212121;
  font-weight: 700;
  font-size: 1.8rem;
  margin: 0; }

.cs-minicart__items-total {
  margin-left: 0.5rem; }
  .cs-minicart__items-total:before {
    content: '('; }
  .cs-minicart__items-total:after {
    content: ')'; }

.cs-minicart__edit-link {
  font-weight: 700; }
  .cs-minicart__edit-link .cs-link__icon {
    padding: 0 0 0 1rem; }

.cs-minicart__content {
  height: auto !important;
  background-color: #fff;
  position: relative; }
  .cs-minicart__content .load.indicator:before {
    height: unset; }

.cs-minicart__list {
  padding: 0;
  list-style: none;
  margin-bottom: 0; }
  .cs-minicart__list--scroll {
    height: auto !important;
    max-height: calc(100vh - 36rem);
    overflow-y: auto; }
    @media (min-width: 48em) {
      .cs-minicart__list--scroll {
        max-height: calc(100vh - 38rem); }
        .is-tablet .cs-minicart__list--scroll {
          max-height: calc(100vh - 43rem); } }

.cs-minicart__list-item {
  padding: 1.5rem;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 0; }
  .cs-minicart__list-item:last-child {
    border-bottom: none; }

.cs-minicart__actions-wrapper {
  padding: 2rem 0;
  position: relative; }

.cs-minicart__actions {
  margin-top: 1rem; }
  .cs-minicart__actions:first-child {
    margin-top: 0; }

.cs-minicart__empty {
  padding: 2rem 0;
  display: inline-block;
  font-size: 1.4rem;
  text-align: center; }

.cs-minicart__subtotal {
  font-weight: 700;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem; }

.cs-minicart__subtotal-price .price {
  font-weight: 700;
  font-size: 1.5rem !important; }

.cs-minicart__button-checkout {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  position: relative;
  line-height: 1.275em;
  margin: 0;
  text-transform: none;
  padding: 0.75em 1em;
  box-sizing: border-box;
  transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
  vertical-align: middle;
  z-index: 5;
  will-change: transform, background-color, border-color, fill, color;
  width: 100%; }
  .cs-minicart__button-checkout:hover, .cs-minicart__button-checkout:focus, .cs-minicart__button-checkout:active {
    background-color: #324dcf !important; }
  .cs-minicart__button-checkout:before, .cs-minicart__button-checkout:after {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    right: -1px; }
  .cs-minicart__button-checkout:before {
    width: 5.5rem;
    border-radius: 0 3px 3px 0;
    z-index: -1;
    background: linear-gradient(100deg, transparent 0%, transparent 15%, #142b9e 15%, #142b9e 100%); }
  .cs-minicart__button-checkout:after {
    width: 4rem;
    min-width: auto;
    background-color: #142b9e;
    transition: width 0.25s linear;
    z-index: -2;
    border-radius: 3px; }
  .cs-minicart__button-checkout:hover:after {
    width: calc(100% + 2px); }
  .cs-minicart__button-checkout::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0; }
  .cs-minicart__button-checkout, .cs-minicart__button-checkout:visited {
    cursor: pointer;
    border-radius: 3px;
    outline-offset: 1px;
    text-decoration: none;
    box-shadow: none;
    border: 1px solid transparent;
    color: #fff;
    background-color: #324dcf; }
    .cs-minicart__button-checkout *, .cs-minicart__button-checkout:visited * {
      color: #fff;
      fill: #fff; }
  .cs-minicart__button-checkout:hover:not([disabled]), .cs-minicart__button-checkout:focus:not([disabled]), .cs-minicart__button-checkout:active:not([disabled]) {
    box-shadow: none;
    outline: none;
    text-decoration: none;
    border: 1px solid transparent;
    color: #fff;
    background-color: #142b9e; }
    .cs-minicart__button-checkout:hover:not([disabled]) *, .cs-minicart__button-checkout:focus:not([disabled]) *, .cs-minicart__button-checkout:active:not([disabled]) * {
      color: #fff;
      fill: #fff; }
  .cs-minicart__button-checkout[disabled] {
    opacity: 0.65;
    cursor: auto; }
  .cs-minicart__button-checkout-span {
    margin-top: 0;
    z-index: 1;
    font-weight: 700;
    vertical-align: baseline;
    display: inline-block;
    line-height: 1.275em;
    letter-spacing: normal;
    padding-right: 4em;
    /* stylelint-disable-next-line */ }
  .cs-minicart__button-checkout-icon {
    max-width: 1em;
    max-height: 1.4rem;
    z-index: 2;
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    margin-right: 0;
    right: 1em;
    /* stylelint-disable-next-line */ }

.cs-minicart__button-viewcart {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  position: relative;
  line-height: 1.275em;
  margin: 0;
  text-transform: none;
  padding: 0.75em 1em;
  box-sizing: border-box;
  transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
  vertical-align: middle;
  z-index: 5;
  will-change: transform, background-color, border-color, fill, color;
  width: 100%; }
  .cs-minicart__button-viewcart::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0; }
  .cs-minicart__button-viewcart, .cs-minicart__button-viewcart:visited {
    cursor: pointer;
    border-radius: 3px;
    outline-offset: 1px;
    text-decoration: none;
    box-shadow: none;
    border: 1px solid #324dcf;
    color: #324dcf;
    background-color: #fff; }
    .cs-minicart__button-viewcart *, .cs-minicart__button-viewcart:visited * {
      color: #324dcf;
      fill: #324dcf; }
  .cs-minicart__button-viewcart:hover:not([disabled]), .cs-minicart__button-viewcart:focus:not([disabled]), .cs-minicart__button-viewcart:active:not([disabled]) {
    box-shadow: none;
    outline: none;
    text-decoration: none;
    border: 1px solid #142b9e;
    color: #fff;
    background-color: #142b9e; }
    .cs-minicart__button-viewcart:hover:not([disabled]) *, .cs-minicart__button-viewcart:focus:not([disabled]) *, .cs-minicart__button-viewcart:active:not([disabled]) * {
      color: #fff;
      fill: #fff; }
  .cs-minicart__button-viewcart[disabled] {
    opacity: 0.65;
    cursor: auto; }
  .cs-minicart__button-viewcart-span {
    margin-top: 0;
    z-index: 1;
    font-weight: 700;
    vertical-align: baseline;
    display: inline-block;
    line-height: 1.275em;
    letter-spacing: normal;
    padding-right: 4em;
    /* stylelint-disable-next-line */ }
  .cs-minicart__button-viewcart-icon {
    max-width: 1em;
    max-height: 1.4rem;
    z-index: 2;
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    margin-right: 0;
    right: 1em;
    /* stylelint-disable-next-line */ }

.minicart-wrapper .ui-dialog {
  position: absolute;
  top: 100%;
  right: 0.5rem;
  z-index: 99999; }
  @media (min-width: 64em) {
    .minicart-wrapper .ui-dialog {
      right: -1rem; } }
  @media (min-width: 80em) {
    .minicart-wrapper .ui-dialog {
      top: calc( 100% - ( ( 11rem - 8.3rem ) / 2 )); } }

.minicart-wrapper .block-minicart {
  background: #f0f0f0;
  box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.27);
  border: none;
  width: 31rem;
  right: 0;
  margin: 0;
  padding: 0 2rem;
  position: relative; }
  @media (min-width: 30em) {
    .minicart-wrapper .block-minicart {
      width: 40rem; } }
  .minicart-wrapper .block-minicart:before {
    display: none; }
  .minicart-wrapper .block-minicart:after {
    content: '';
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    background: #f0f0f0;
    transform: rotate(45deg);
    top: -0.8rem;
    right: 1.5rem;
    left: auto; }
    @media (min-width: 64em) {
      .minicart-wrapper .block-minicart:after {
        right: 4.5rem; } }
