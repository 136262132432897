/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.password-strength-meter {
  font-size: 1.2rem;
  background-color: initial;
  border: none;
  border-top-width: 0;
  background-size: 2rem 0.7rem;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: calc(100% - 1rem), calc(100% - (2.5rem + 1rem)), calc(100% - (2.5rem * 2 + 1rem)), calc(100% - (2.5rem * 3 + 1rem)), calc(100% - (2.5rem * 4 + 1rem)), calc(100% - (2.5rem * 5 + 1rem));
  background-image: url("../images/icons/forms/icon-password-strength-meter.svg"), url("../images/icons/forms/icon-password-strength-meter.svg"), url("../images/icons/forms/icon-password-strength-meter.svg"), url("../images/icons/forms/icon-password-strength-meter.svg"), url("../images/icons/forms/icon-password-strength-meter.svg"), url("../images/icons/forms/icon-password-strength-meter.svg"); }
  @media (max-width: 29.99em) {
    .password-strength-meter {
      font-size: 1rem;
      padding: 0 0.5rem; } }
  .password-strength-meter:before {
    display: none; }
  .password-weak .password-strength-meter {
    background-image: url("../images/icons/forms/icon-password-strength-meter.svg"), url("../images/icons/forms/icon-password-strength-meter.svg"), url("../images/icons/forms/icon-password-strength-meter.svg"), url("../images/icons/forms/icon-password-strength-meter.svg"), url("../images/icons/forms/icon-password-strength-meter-weak.svg"), url("../images/icons/forms/icon-password-strength-meter-weak.svg"); }
  .password-medium .password-strength-meter {
    background-image: url("../images/icons/forms/icon-password-strength-meter.svg"), url("../images/icons/forms/icon-password-strength-meter.svg"), url("../images/icons/forms/icon-password-strength-meter.svg"), url("../images/icons/forms/icon-password-strength-meter-medium.svg"), url("../images/icons/forms/icon-password-strength-meter-medium.svg"), url("../images/icons/forms/icon-password-strength-meter-medium.svg"); }
  .password-strong .password-strength-meter {
    background-image: url("../images/icons/forms/icon-password-strength-meter.svg"), url("../images/icons/forms/icon-password-strength-meter-strong.svg"), url("../images/icons/forms/icon-password-strength-meter-strong.svg"), url("../images/icons/forms/icon-password-strength-meter-strong.svg"), url("../images/icons/forms/icon-password-strength-meter-strong.svg"), url("../images/icons/forms/icon-password-strength-meter-strong.svg"); }
  .password-very-strong .password-strength-meter {
    background-image: url("../images/icons/forms/icon-password-strength-meter-very-strong.svg"), url("../images/icons/forms/icon-password-strength-meter-very-strong.svg"), url("../images/icons/forms/icon-password-strength-meter-very-strong.svg"), url("../images/icons/forms/icon-password-strength-meter-very-strong.svg"), url("../images/icons/forms/icon-password-strength-meter-very-strong.svg"), url("../images/icons/forms/icon-password-strength-meter-very-strong.svg"); }
  @media (max-width: 29.99em) {
    .password-strength-meter {
      background-size: 1.5rem 0.5rem;
      background-position-x: calc(100% - 1rem), calc( 100% - ( 1.7rem + 1rem )), calc( 100% - ( 1.7rem * 2 + 1rem )), calc( 100% - ( 1.7rem * 3 + 1rem )), calc( 100% - ( 1.7rem * 4 + 1rem )), calc( 100% - ( 1.7rem * 5 + 1rem )); } }
