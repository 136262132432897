@import 'config/variables';
@import 'vendors/include-media';
@import 'components/button/mixin';
@import 'components/field/mixin';

$captcha_button-type: 'secondary' !default;
$captcha_button-icon-pos: 'no-icon' !default;

.#{$ns}captcha {
    $root: &;

    &__image-controls {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 1.5rem;

        .#{$ns}dashboard & {
            flex-direction: column;
            align-items: flex-start;

            @include media('>=phoneLg') {
                flex-direction: row;
                align-items: flex-end;
            }
        }

        @include media('<=tablet') {
            flex-wrap: wrap;
        }
    }

    &__image {
        margin-right: 1rem;
    }

    &__button {
        @include button(
            $type: $captcha_button-type,
            $icon_pos: $captcha_button-icon-pos
        );

        .#{$ns}dashboard & {
            margin-top: 2rem;

            @include media('>=phoneLg') {
                margin-top: 0;
            }
        }
    }

    &__button-span {
        @include button_span(
            $type: $captcha_button-type,
            $icon_pos: $captcha_button-icon-pos
        );
    }

    &__note {
        @include field-note();
    }

    &__wrapper {
        .#{$ns}dashboard & {
            width: 100%;
            margin-bottom: 2rem;
        }
    }
}
