@import 'config/variables';
@import 'components/typography/mixin';

$page-title_font-size: $font-size_headline-1 !default;
$page-title_text-transform: none !default;
$page-title_font-weight: 600 !default;

$page-title_span-color: $color_text-500 !default;
$page-title_span-font-weight: 300 !default;
$page-title_span-padding: 0 !default;

.page-title {
    @include headline($level: $page-title_font-size);
    padding-bottom: 1rem;
    text-transform: $page-title_text-transform;
    font-weight: $page-title_font-weight;

    &__headline {
        margin: 0;
        padding: 0;
        font-size: inherit;
        font-weight: inherit;
        font-family: inherit;
        display: inline;
    }

    &__span {
        color: $page-title_span-color;
        font-weight: $page-title_span-font-weight;
        padding: $page-title_span-padding;
    }
}
