/**
 * Footer component customization.
 * @see /Magento_Theme/templates/footer/columns.phtml for templates.
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-footer {
  font-size: 1rem;
  color: #fff;
  background: #000;
  overflow: hidden;
  padding: 0 0 2rem; }
  @media (min-width: 48em) {
    .cs-footer {
      padding: 0 1.5rem; } }
  .cs-footer__content {
    padding: 0; }
    @media (min-width: 48em) {
      .cs-footer__content {
        display: flex;
        flex-wrap: wrap;
        padding-top: 4rem;
        padding-bottom: 2.5rem; } }
    @media (min-width: 64em) {
      .cs-footer__content {
        flex-wrap: nowrap; } }
  @media (min-width: 64em) {
    .cs-footer__item {
      flex: 1; } }
  .cs-footer__item--promo {
    flex-basis: 100%; }
    @media (max-width: 47.99em) {
      .cs-footer__item--promo {
        display: none; } }
    @media (max-width: 63.99em) {
      .cs-footer__item--promo {
        margin-bottom: 1.5rem; } }
    @media (min-width: 64em) {
      .cs-footer__item--promo {
        flex-basis: auto;
        flex-grow: 1; } }
  @media (min-width: 48em) {
    .cs-footer__item--links {
      display: flex;
      flex-basis: 100%;
      margin-top: 2em; } }
  @media (min-width: 48em) {
    .cs-footer__item--links {
      margin-top: 0; } }
  @media (min-width: 64em) {
    .cs-footer__item--links {
      flex-basis: auto;
      flex-grow: 5; } }
  @media (min-width: 48em) {
    .cs-footer__item--extras {
      display: flex;
      flex-basis: 100%; } }
  @media (min-width: 64em) {
    .cs-footer__item--extras {
      display: block;
      flex-basis: 25%;
      flex-grow: 2; } }
  @media (min-width: 48em) {
    .cs-footer__section {
      flex: 1; } }
  @media (min-width: 48em) {
    .cs-footer__section--newsletter {
      flex-grow: 2; } }
  @media (min-width: 64em) {
    .cs-footer__section--socials {
      margin-top: 4rem; } }
  @media (min-width: 64em) {
    .cs-footer__section--stores-switcher {
      margin-top: 4rem;
      max-width: 20rem; } }
  .cs-footer__section-title,
  .cs-footer [data-role='title'] {
    font-family: "Libre Franklin", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Oxygen, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.5rem;
    color: #212121;
    font-weight: 700;
    text-transform: uppercase;
    margin: 1rem 0 2rem;
    position: relative;
    margin: 0;
    border-top: 1px solid #888;
    padding: 2em 1.5rem;
    color: #888;
    font-size: 1.2em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.08rem; }
    @media (max-width: 47.99em) {
      .cs-footer__section[data-mage-init] .cs-footer__section-title,
      .cs-footer__section[data-collapsible] .cs-footer__section-title, .cs-footer__section[data-mage-init]
      .cs-footer [data-role='title'],
      .cs-footer__section[data-collapsible]
      .cs-footer [data-role='title'] {
        cursor: pointer;
        position: relative; }
        .cs-footer__section[data-mage-init] .cs-footer__section-title:before, .cs-footer__section[data-mage-init] .cs-footer__section-title:after,
        .cs-footer__section[data-collapsible] .cs-footer__section-title:before,
        .cs-footer__section[data-collapsible] .cs-footer__section-title:after, .cs-footer__section[data-mage-init]
        .cs-footer [data-role='title']:before, .cs-footer__section[data-mage-init]
        .cs-footer [data-role='title']:after,
        .cs-footer__section[data-collapsible]
        .cs-footer [data-role='title']:before,
        .cs-footer__section[data-collapsible]
        .cs-footer [data-role='title']:after {
          content: '';
          position: absolute;
          top: 50%;
          width: 1.2rem;
          height: 0.2rem;
          left: auto;
          background-color: #888;
          transition: transform 0.3s ease-out, opacity 0.15s ease-out, background-color 0.15s linear;
          backface-visibility: hidden; }
        .cs-footer__section[data-mage-init] .cs-footer__section-title:before, .cs-footer__section[data-mage-init] .cs-footer__section-title:after,
        .cs-footer__section[data-collapsible] .cs-footer__section-title:before,
        .cs-footer__section[data-collapsible] .cs-footer__section-title:after, .cs-footer__section[data-mage-init]
        .cs-footer [data-role='title']:before, .cs-footer__section[data-mage-init]
        .cs-footer [data-role='title']:after,
        .cs-footer__section[data-collapsible]
        .cs-footer [data-role='title']:before,
        .cs-footer__section[data-collapsible]
        .cs-footer [data-role='title']:after {
          right: 1.5rem;
          transform: translate3d(0, -50%, 0) !important; }
        .cs-footer__section[data-mage-init] .cs-footer__section-title:after,
        .cs-footer__section[data-collapsible] .cs-footer__section-title:after, .cs-footer__section[data-mage-init]
        .cs-footer [data-role='title']:after,
        .cs-footer__section[data-collapsible]
        .cs-footer [data-role='title']:after {
          transform: translate3d(0, -50%, 0) rotate(90deg) !important; }
        .cs-footer__section[data-mage-init] .cs-footer__section-title[aria-expanded="true"]:before,
        .cs-footer__section[data-collapsible] .cs-footer__section-title[aria-expanded="true"]:before, .cs-footer__section[data-mage-init]
        .cs-footer [data-role='title'][aria-expanded="true"]:before,
        .cs-footer__section[data-collapsible]
        .cs-footer [data-role='title'][aria-expanded="true"]:before {
          transform: translate3d(0, -50%, 0) rotate(-90deg) !important;
          opacity: 0; }
        .cs-footer__section[data-mage-init] .cs-footer__section-title[aria-expanded="true"]:after,
        .cs-footer__section[data-collapsible] .cs-footer__section-title[aria-expanded="true"]:after, .cs-footer__section[data-mage-init]
        .cs-footer [data-role='title'][aria-expanded="true"]:after,
        .cs-footer__section[data-collapsible]
        .cs-footer [data-role='title'][aria-expanded="true"]:after {
          transform: translate3d(0, -50%, 0) rotate(0) !important; }
      .cs-footer__section--socials .cs-footer__section-title, .cs-footer__section--socials
      .cs-footer [data-role='title'] {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        white-space: nowrap;
        /* 1 */ } }
    @media (min-width: 48em) {
      .cs-footer__section-title,
      .cs-footer [data-role='title'] {
        border-top: none;
        padding-left: 0; } }
    @media (min-width: 64em) {
      .cs-footer__section-title,
      .cs-footer [data-role='title'] {
        padding-top: 0;
        padding-right: 0; } }
  .cs-footer__section-content,
  .cs-footer [data-role='content'] {
    padding: 0 1.5rem 1.5rem;
    font-size: 1.2em; }
    @media (max-width: 47.99em) {
      .cs-footer__section[data-mage-init] .cs-footer__section-content,
      .cs-footer__section[data-collapsible] .cs-footer__section-content, .cs-footer__section[data-mage-init]
      .cs-footer [data-role='content'],
      .cs-footer__section[data-collapsible]
      .cs-footer [data-role='content'] {
        display: none; } }
    @media (min-width: 48em) {
      .cs-footer__section-content,
      .cs-footer [data-role='content'] {
        padding-left: 0; } }
    @media (min-width: 64em) {
      .cs-footer__section-content,
      .cs-footer [data-role='content'] {
        padding-right: 0;
        padding-bottom: 0; } }
  .cs-footer__shop-logo {
    display: block;
    width: 13.5em;
    height: 2.3em;
    min-width: 13.5em;
    max-width: 100%;
    max-height: 100%; }

.cs-container--footer {
  max-width: none; }
