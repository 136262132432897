/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-reviews-summary {
  line-height: 1;
  display: flex;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  flex: 1 0 100%;
  border-bottom: 1px solid #dcdcdc; }
  @media (min-width: 64em) {
    .cs-reviews-summary {
      flex: 0 0 35rem;
      margin-bottom: 0;
      margin-right: 2.5rem;
      padding-right: 2.5rem;
      border-right: 1px solid #dcdcdc;
      border-bottom: 0;
      align-content: flex-start; } }
  .cs-reviews-summary__note {
    font-size: 4rem;
    line-height: 100%;
    color: #212121;
    font-weight: 300;
    margin-bottom: 0.5rem; }
  .cs-reviews-summary__note-right {
    color: #696969; }
  .cs-reviews-summary__button-wrapper {
    width: 100%;
    margin-top: 2.5rem;
    padding: 2.5rem 0;
    border-top: 1px solid #dcdcdc; }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-reviews-summary__button-wrapper {
    margin-top: 0;
    border-top: 0; } }
  .cs-reviews-summary__button {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    will-change: transform, background-color, border-color, fill, color;
    font-size: 1.4rem; }
    .cs-reviews-summary__button:hover, .cs-reviews-summary__button:focus, .cs-reviews-summary__button:active {
      background-color: #324dcf !important; }
    .cs-reviews-summary__button:before, .cs-reviews-summary__button:after {
      content: '';
      position: absolute;
      top: -1px;
      bottom: -1px;
      right: -1px; }
    .cs-reviews-summary__button:before {
      width: 5.5rem;
      border-radius: 0 3px 3px 0;
      z-index: -1;
      background: linear-gradient(100deg, transparent 0%, transparent 15%, #142b9e 15%, #142b9e 100%); }
    .cs-reviews-summary__button:after {
      width: 4rem;
      min-width: auto;
      background-color: #142b9e;
      transition: width 0.25s linear;
      z-index: -2;
      border-radius: 3px; }
    .cs-reviews-summary__button:hover:after {
      width: calc(100% + 2px); }
    .cs-reviews-summary__button::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-reviews-summary__button, .cs-reviews-summary__button:visited {
      cursor: pointer;
      border-radius: 3px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #324dcf; }
      .cs-reviews-summary__button *, .cs-reviews-summary__button:visited * {
        color: #fff;
        fill: #fff; }
    .cs-reviews-summary__button:hover:not([disabled]), .cs-reviews-summary__button:focus:not([disabled]), .cs-reviews-summary__button:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #142b9e; }
      .cs-reviews-summary__button:hover:not([disabled]) *, .cs-reviews-summary__button:focus:not([disabled]) *, .cs-reviews-summary__button:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .cs-reviews-summary__button[disabled] {
      opacity: 0.65;
      cursor: auto; }
    @media (max-width: 29.99em) {
      .cs-reviews-summary__button {
        width: 100%; } }
  .cs-reviews-summary__button-span {
    margin-top: 0;
    z-index: 1;
    font-weight: 700;
    vertical-align: baseline;
    display: inline-block;
    line-height: 1.275em;
    letter-spacing: normal;
    padding-right: 4em; }
  .cs-reviews-summary__button-icon {
    max-width: 1em;
    max-height: 1.4rem;
    z-index: 2;
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    margin-right: 0;
    right: 1em; }
  .cs-reviews-summary__stars {
    margin-right: 1rem;
    flex: 1 0 auto; }
    @media (min-width: 23.125em) {
      .cs-reviews-summary__stars {
        flex: 0 1 auto;
        margin-right: 4rem; } }
    @media (min-width: 48em) {
      .cs-reviews-summary__stars {
        flex: 0 1 100%; } }
  .cs-reviews-summary__bars {
    font-size: 1.4rem;
    list-style: none inside none;
    padding: 0;
    margin: 2.5rem 0 0;
    flex-grow: 1;
    color: #696969;
    max-width: 40rem;
    width: 100%; }
    @media (min-width: 23.125em) {
      .cs-reviews-summary__bars {
        margin-top: 0;
        width: auto; } }
    @media (min-width: 48em) {
      .cs-reviews-summary__bars {
        margin-top: 2.5rem; } }
  .cs-reviews-summary__bar {
    position: relative;
    vertical-align: bottom;
    display: flex;
    align-items: center;
    margin: 0 0 1rem 0; }
    .cs-reviews-summary__bar:last-child {
      margin-bottom: 0; }
    .cs-reviews-summary__bar-label {
      flex: 0 1 3.5em; }
    .cs-reviews-summary__bar-count {
      width: 1.5em;
      text-align: right; }
    .cs-reviews-summary__bar-progress {
      flex: 1 0 auto;
      position: relative;
      display: inline-block;
      min-width: 9rem;
      height: 0.9em;
      margin: 0 0.8rem 0 0.7rem;
      background: #f0f0f0; }
      .cs-reviews-summary__bar-progress-inner {
        position: absolute;
        left: 0;
        top: 0;
        height: inherit;
        background: #fedfa4; }
  .cs-reviews-summary__ratings-averages {
    list-style-type: none;
    width: 100%;
    margin: 2.5rem 0 0;
    padding: 2.5rem 0 0;
    border-top: 1px solid #dcdcdc;
    line-height: 1.43; }
    .cs-reviews-summary__ratings-averages-item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 0.3em; }
      @media (min-width: 23.125em) {
        .cs-reviews-summary__ratings-averages-item {
          max-width: 25rem; } }
      @media (min-width: 48em) {
        .cs-reviews-summary__ratings-averages-item {
          max-width: 40rem; } }
      .cs-reviews-summary__ratings-averages-item:last-child {
        margin-bottom: 0; }
