/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.messages {
  max-width: 120rem;
  margin: 0 auto; }

.cs-messages__message,
.message {
  margin: 1rem 0;
  padding: 1.2rem 1rem 1rem;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-position: 1.2rem center;
  background-size: 2rem 2rem;
  padding-left: 4.3rem; }

.cs-messages__message--success,
.message.success {
  background-color: #d5efd7;
  background-image: url("../images/icons/messages/icon-success.svg");
  border: none; }

.cs-messages__message--notice,
.message.warning {
  background-color: #fdf4ca;
  background-image: url("../images/icons/messages/icon-warning.svg");
  border: none; }

.cs-messages__message--info,
.message.info,
.message.notice {
  background-color: #fdf4ca;
  background-image: url("../images/icons/messages/icon-info.svg");
  border: none; }

.cs-messages__message--error,
.message.error {
  background-color: #f8d0ce;
  background-image: url("../images/icons/messages/icon-error.svg");
  border: none; }

.cs-messages__text,
.message span {
  color: #696969;
  font-size: 1.4rem; }

.cs-messages--space-top {
  margin-top: 1.5rem; }
