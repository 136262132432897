/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Mixins for flexible containers declaration.
 */
/**
 * Defines page container. This mixin makes sure that the content has always equal padding
 * for mobile screens on both size of the device.
 */
/**
 * Negates parent container's padding and sets it own.
 * Somethimes this has to be done to make background even to other containers' content.
 */
/**
 * Defines container wrapper that makes sure contnet doesn't extend above certain width and is always centered.
 */
.cs-header {
  font-size: 1rem;
  height: 5rem; }
  @media (min-width: 48em) {
    .cs-header {
      height: 8.3rem; } }
  @media (min-width: 80em) {
    .cs-header {
      height: 11rem; } }
  .cs-header__content {
    height: 5rem;
    z-index: 300;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    will-change: z-index, height, position;
    background-color: #fff;
    position: relative;
    max-width: 100vw; }
    @media (max-width: 63.99em) {
      .cs-header__content {
        position: fixed;
        top: 0;
        left: 0;
        right: 0; } }
    @media (min-width: 48em) {
      .cs-header__content {
        height: 8.3rem; } }
    @media (min-width: 80em) {
      .cs-header__content {
        height: 11rem; } }
    @media (min-width: 64em) {
      .cs-header__content {
        border-bottom: 1px solid #f0f0f0;
        flex-wrap: nowrap; } }
  .cs-header__offcanvas-toggle {
    height: 100%;
    flex: 0 1 4rem; }
    @media (min-width: 64em) {
      .cs-header__offcanvas-toggle {
        display: none; } }
  .cs-header__logo {
    display: flex;
    flex: 1 1 0%;
    margin-bottom: 0;
    height: 100%; }
    @media (min-width: 30em) {
      .cs-header__logo {
        flex: 0 1 auto; } }
    @media (max-width: 47.99em) {
      .cs-header__logo {
        padding: 0.7rem 0; } }
    .page-print .cs-header__logo {
      max-width: 120rem;
      width: 100%;
      margin: 0 auto;
      position: relative;
      height: auto;
      padding: 2rem 0; }
      .page-print .cs-header__logo:after {
        content: '';
        display: table;
        clear: both; }
  .cs-header__search {
    display: none;
    width: 100%;
    background-color: #fff;
    padding: 1rem;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 300; }
    @media (min-width: 64em) {
      .cs-header__search {
        flex-grow: 1;
        width: auto;
        padding: 0;
        position: initial;
        margin-left: 2rem !important;
        justify-content: center; }
        .ie11 .cs-header__search {
          position: inherit;
          top: inherit; } }
    .cs-header__search--active {
      display: flex; }
    @media (min-width: 64em) {
      .cs-header__search {
        display: flex; } }
    @media (min-width: 64em) {
      .cs-header__search {
        position: relative;
        padding: 0;
        top: initial; } }
  .cs-header__user-nav {
    display: flex;
    justify-content: flex-end;
    flex: 0 1 30%; }
    @media (min-width: 64em) {
      .cs-header__user-nav {
        flex: 0 1 25%; } }
