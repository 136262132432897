/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* Radio and checkbox styling */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-reviews {
  flex: 1 1 0; }
  .ie11 .cs-reviews {
    flex-basis: auto; }
  @media (max-width: 63.99em) {
    .cs-reviews {
      border-bottom: 1px solid #dcdcdc; } }
  .cs-reviews__title {
    background: #f0f0f0; }
    @media (min-width: 48em) {
      .cs-reviews__title {
        background: none;
        padding: 0; } }
  .cs-reviews__list {
    list-style: none;
    margin: 0;
    padding: 0; }
  .cs-reviews__label {
    float: left;
    line-height: 4rem; }
  .cs-reviews__item {
    padding: 0 0 2.5rem;
    margin: 0 0 2.5rem;
    border-bottom: 1px solid #dcdcdc;
    list-style: none; }
    .cs-reviews__item:last-of-type {
      border-bottom: 0;
      margin-bottom: 0; }
  .cs-reviews__form {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    margin: 2.5rem 0; }
    @media (min-width: 64em) {
      .cs-reviews__form {
        background: #f0f0f0;
        padding: 3rem; } }
  .cs-reviews__headline {
    width: 100%;
    margin: 0 0 1.4em;
    text-align: left;
    font-size: 1.8rem; }
  .cs-reviews__product-name {
    font-weight: 200; }
  .cs-reviews__rating-item:not(:first-of-type) {
    margin-top: 1em; }
  .cs-reviews__ratings {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 1.5em;
    font-size: 1.4rem; }
    @media (min-width: 64em) {
      .cs-reviews__ratings {
        width: 50%;
        padding-right: 3rem;
        border-right: 1px solid #dcdcdc; } }
  .cs-reviews__fieldset {
    width: 100%;
    margin-bottom: 1.5em;
    text-align: left;
    box-sizing: border-box; }
    @media (min-width: 64em) {
      .cs-reviews__fieldset {
        width: 50%;
        padding-left: 3rem; } }
  .cs-reviews__submit {
    width: 100%;
    display: flex;
    justify-content: flex-start; }
    @media (min-width: 64em) {
      .cs-reviews__submit {
        justify-content: center; } }
  .cs-reviews__submit-button {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    will-change: transform, background-color, border-color, fill, color; }
    .cs-reviews__submit-button::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-reviews__submit-button, .cs-reviews__submit-button:visited {
      cursor: pointer;
      border-radius: 3px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #324dcf; }
      .cs-reviews__submit-button *, .cs-reviews__submit-button:visited * {
        color: #fff;
        fill: #fff; }
    .cs-reviews__submit-button:hover:not([disabled]), .cs-reviews__submit-button:focus:not([disabled]), .cs-reviews__submit-button:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #142b9e; }
      .cs-reviews__submit-button:hover:not([disabled]) *, .cs-reviews__submit-button:focus:not([disabled]) *, .cs-reviews__submit-button:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .cs-reviews__submit-button[disabled] {
      opacity: 0.65;
      cursor: auto; }
    @media (max-width: 30em) {
      .cs-reviews__submit-button {
        width: 100%; } }
  .cs-reviews__rating-control {
    box-sizing: content-box;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    padding: 0.75em 1em;
    height: 1.275em;
    background: transparent;
    margin: 0 0 0.2em;
    display: flex;
    align-items: center; }
  .cs-reviews__rate-stars {
    box-sizing: border-box;
    order: 0; }
  .cs-reviews__rate-feedback {
    order: 1;
    font-size: 0.85em;
    line-height: 0.9em;
    color: #888;
    font-weight: 700;
    margin-left: 1.5rem;
    display: flex;
    align-items: center; }
  .cs-reviews__review-field {
    min-height: 10rem;
    resize: vertical; }
  .cs-reviews .cs-pagination__content {
    justify-content: center;
    margin: 2.5rem 0; }
