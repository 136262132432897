/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Using psuedo elements to maintain an elements aspect ratio, even as it scales
 * It's using only for IE11 and Edge (version which doesn't support native CSS grid) browsers
 * For browsers which support native CSS grid, we are using mixins contain `reset` in mixin name
 * to override default mixins (progressive enhancement)
 * https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
 * @param {number} $width: width of the element;
 * @param {number} $height: height of the element;
 * @param {string} $contentClass: class of the direct child element to be positioned absolutely;
 * @param {boolean} $autoposition: tells if item shall take 100% of parent size or if it can be wider/higher if needed;
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-navigation {
  box-sizing: border-box;
  font-size: 1rem; }
  .cs-navigation__list {
    list-style: none;
    padding: 0;
    margin: 0; }
    .cs-navigation__list--main {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between; }
    .cs-navigation__list--all-categories {
      width: 100%;
      columns: auto 1;
      column-gap: 0;
      flex: 1 0 auto; }
    .cs-navigation__list--level_1 {
      width: 100%;
      columns: auto 1;
      column-gap: 0;
      flex: 1 0 auto; }
    .cs-navigation__list--level_2 {
      flex-wrap: wrap; }
    .cs-navigation__list--hidden {
      display: none; }
      .cs-navigation__list--hidden + .cs-navigation__extras {
        display: none; }
    .cs-navigation__list .cs-navigation__item--no-children:nth-child(2) {
      padding-top: 1rem; }
    .cs-navigation__list .cs-navigation__item--no-children:last-of-type {
      padding-bottom: 1rem; }
  .cs-navigation__item {
    font-size: 0.9em;
    margin: 0;
    width: 100%; }
    .cs-navigation__item--active:not([data-category-identifier='all-categories']) > .cs-navigation__link, .cs-navigation__item.active:not([data-category-identifier='all-categories']) > .cs-navigation__link, .cs-navigation__item:hover:not([data-category-identifier='all-categories']) > .cs-navigation__link {
      color: #324dcf; }
    .cs-navigation__item--main {
      width: auto;
      font-size: 1.4em; }
      .cs-navigation__item--main:first-of-type > .cs-navigation__link {
        padding-left: 0; }
      .cs-navigation__item--main:last-of-type > .cs-navigation__link {
        padding-right: 0; }
    .cs-navigation__item--all-categories {
      padding: 1rem;
      page-break-inside: avoid;
      /* Theoretically FF 20+ */
      break-inside: avoid-column;
      /* IE 11 */
      display: table; }
    .cs-navigation__item--level_1 {
      padding: 1rem;
      page-break-inside: avoid;
      /* Theoretically FF 20+ */
      break-inside: avoid-column;
      /* IE 11 */
      display: table; }
    .cs-navigation__item--hidden {
      display: none; }
  .cs-navigation__link {
    display: block;
    text-transform: uppercase;
    padding: 0.9em 1.05em;
    width: 100%; }
    .cs-navigation__link, .cs-navigation__link:visited {
      color: #484848; }
    .cs-navigation__link:hover {
      text-decoration: none;
      color: #324dcf; }
    .cs-navigation__link--main {
      font-weight: 700; }
    .cs-navigation__link--all-categories {
      font-weight: 700;
      margin: 0;
      border-bottom: 1px solid #dcdcdc;
      padding-bottom: 1.55em; }
    .cs-navigation__link--level_1 {
      font-weight: 700;
      margin: 0;
      border-bottom: 1px solid #dcdcdc;
      padding-bottom: 1.55em; }
    .cs-navigation__link--level_2 {
      text-transform: none;
      font-size: 1.25em; }
    .cs-navigation__link--with-icon {
      position: relative;
      padding-left: 2.8rem; }
    .cs-navigation__link-icon-wrapper {
      position: absolute;
      width: 2rem;
      top: 50%;
      left: 0;
      transform: translateY(-50%); }
    .cs-navigation__link-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      max-height: 1.8rem; }
      .cs-navigation__link--category .cs-navigation__link-icon {
        margin-top: -0.5rem; }
    .cs-navigation__link-products-qty:before {
      content: '('; }
    .cs-navigation__link-products-qty:after {
      content: ')'; }
  .cs-navigation .cs-navigation__item--no-children {
    padding: 0; }
  .cs-navigation .cs-navigation__item--no-children > .cs-navigation__link--level_1 {
    text-transform: none;
    font-size: 1.12em;
    font-weight: 400;
    padding: 0.9em 1.5em;
    border: none;
    color: #484848; }
  .cs-navigation__flyout {
    position: absolute;
    display: none;
    background: #fff;
    left: 0;
    backface-visibility: hidden;
    z-index: 1001;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
    margin-top: -1px; }
    .cs-navigation__flyout--visible {
      display: flex; }
    .cs-navigation__flyout svg {
      pointer-events: none; }
    @supports (-ms-ime-align: auto) {
      .cs-navigation__flyout li {
        list-style: none;
        list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
        list-style-type: none; } }
  .cs-navigation__teaser {
    position: relative;
    max-height: 100%;
    padding: 1em 0;
    overflow: hidden;
    margin-bottom: 0 !important; }
    .cs-navigation__teaser--hidden {
      display: none; }
    .cs-navigation__teaser .cs-image-teaser__figure {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
  .cs-navigation__products {
    padding: 0.5em 1em;
    min-width: 24rem; }
    .cs-navigation__products-header {
      display: block;
      color: #484848;
      text-transform: uppercase;
      width: 100%;
      font-weight: 700;
      margin: 0;
      border-bottom: 1px solid #dcdcdc;
      padding: 1.1em 1.05em 1.55em;
      font-size: 0.9em; }
    .cs-navigation__products-list {
      margin: 0;
      padding: 0;
      list-style: none; }
  .cs-navigation__product {
    position: relative; }
    .cs-navigation__product:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 170%; }
    .cs-navigation__product .cs-product-tile {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .cs-navigation__overlay {
    display: none;
    position: absolute;
    z-index: 1000;
    background-color: #000;
    opacity: 0;
    left: 0;
    width: 100%;
    height: 0;
    transform: translate3d(0, 3rem, 0);
    transition: opacity 0.15s linear, transform 0.2s cubic-bezier(0, 0.97, 0.05, 0.99); }
    .cs-navigation__overlay--visible {
      opacity: 0.8;
      transform: translate3d(0, 0, 0); }
  .page-print .cs-navigation {
    display: none; }
  @supports (display: grid) and (grid-auto-flow: row) {
    .cs-navigation__product {
      position: relative; }
      .cs-navigation__product:before {
        content: none; }
      .cs-navigation__product .cs-product-tile {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto; } }

.cs-container--navigation {
  display: none; }
  @media (min-width: 64em) {
    .cs-container--navigation {
      display: block; } }
