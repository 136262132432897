/* stylelint-disable block-no-empty */
@mixin pagination-list-hook() {
}

@mixin pagination-item-hook() {
}

@mixin pagination-number-hook() {
}

@mixin pagination-number--current-hook() {
}
/* stylelint-enable */
