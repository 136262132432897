@import 'config/variables';
@import 'vendors/include-media';
@import 'etc/view.scss';
@import 'utils/deep-get/deep-get.scss';

$layout-2col-left_columns-per-row: deep-get(
    $view-xml,
    vars,
    MageSuite_ContentConstructor,
    columns,
    one-column
) !default;
$layout-2col-left_was-separator-called-yet: false !default;

$layout-2col-left_elements-gutter-tablet: $page_edge-gutter !default;
$layout-2col-left_elements-gutter-desktop: $layout-2col-left_elements-gutter-tablet !default;
$layout-2col-left_elements-gutter-desktop: 2 *
    $layout-2col-left_elements-gutter-tablet !default;
$layout-2col-left_elements-separator-apply: true !default;
$layout-2col-left_elements-separator-color: $color_border-200 !default;

$layout-2col-left_layout-align-items: stretch !default;

$layout-2col-left_sidebar-padding-tablet: 0
    $layout-2col-left_elements-gutter-tablet 0 0 !default;
$layout-2col-left_sidebar-padding-desktop: 0
    $layout-2col-left_elements-gutter-desktop 0 0 !default;
$layout-2col-left_sidebar-border-right: 2px solid
    $layout-2col-left_elements-separator-color !default;

$layout-2col-left_main-top-spacing: 1.5rem !default;
$layout-2col-left_main-padding: $layout-2col-left_main-top-spacing 0 0
    $layout-2col-left_elements-gutter-tablet !default;
$layout-2col-left_main-padding-tablet: $layout-2col-left_main-top-spacing 0 0
    $layout-2col-left_elements-gutter-tablet !default;
$layout-2col-left_main-padding-desktop: $layout-2col-left_main-top-spacing 0 0
    $layout-2col-left_elements-gutter-desktop !default;

@mixin two-columns-left($apply-border: true) {
    &__columns {
        @include media('>=tablet') {
            display: flex;
            flex-wrap: wrap;
            align-items: $layout-2col-left_layout-align-items;

            &:before,
            &:after {
                display: none;
            }
        }
    }

    &__messages {
        width: 100%;
    }

    &__sidebar {
        order: 1;

        @each $breakpoint, $itemsPerRow in $layout-2col-left_columns-per-row {
            @if $breakpoint != phone and $breakpoint != phoneLg {
                @include media('>=#{$breakpoint}') {
                    width: calc(100% / #{$itemsPerRow});

                    @if $itemsPerRow >
                        1 and
                        $layout-2col-left_was-separator-called-yet ==
                        false and
                        $layout-2col-left_sidebar-border-right !=
                        none
                    {
                        @if $apply-border == true {
                            border-right: $layout-2col-left_sidebar-border-right;
                        }

                        $layout-2col-left_was-separator-called-yet: true;
                    }

                    @if $breakpoint == 'tablet' {
                        padding: $layout-2col-left_sidebar-padding-tablet;
                    }

                    @if $breakpoint == 'laptop' {
                        padding: $layout-2col-left_sidebar-padding-desktop;
                    }
                }
            }
        }
    }

    &__main {
        order: 2;

        @each $breakpoint, $itemsPerRow in $layout-2col-left_columns-per-row {
            @if $breakpoint != phone and $breakpoint != phoneLg {
                @include media('>=#{$breakpoint}') {
                    width: calc(100% - (100% / #{$itemsPerRow}));

                    @if $breakpoint == 'tablet' {
                        padding: $layout-2col-left_main-padding-tablet;
                    }

                    @if $breakpoint == 'laptop' {
                        padding: $layout-2col-left_main-padding-desktop;
                    }
                }
            }
        }
    }

    .cs-page-category__main--search-no-result {
        width: 100%;
    }
}
