@import 'utils/visually-hidden';
@import 'config/variables';
@import 'components/table/hook';

$head_color: $color_mineshaft !default;
$head_move-on-mobile: true !default;
$head_font-size--mobile: 1.4rem !default;

@mixin table-mobile-layout(
    $head_color: $head_color,
    $head_move-on-mobile: $head_move-on-mobile,
    $head_font-size--mobile: $head_font-size--mobile
) {
    border: none;
    font-size: 1.4rem;
    padding: 0;

    &:last-child {
        padding-bottom: 1.2rem;
    }

    thead {
        display: none;
    }

    tbody td {
        border: none;
        display: block;
        font-size: $head_font-size--mobile;
        padding: 0.2rem;

        &:first-child {
            padding-top: 2rem;
        }

        &:last-child {
            padding-bottom: 1rem;
        }

        @if ($head_move-on-mobile) {
            &:before {
                @include media('<laptop') {
                    padding-right: 10px;
                    content: attr(data-th) ': ';
                    display: inline-block;
                    color: $head_color;
                    font-weight: 700;
                }
            }
        }

        &.actions {
            @include media('<laptop') {
                border-bottom: 1px solid $color_alto;
            }

            &:before {
                @include media('<laptop') {
                    @include visually-hidden;
                }
            }
        }
    }

    tfoot {
        display: block;

        th {
            box-sizing: border-box;
            float: left;
            padding-left: 0;
            padding-right: 0;
            text-align: left;
            width: 70%;
        }
        // stylelint-disable no-descending-specificity
        td {
            box-sizing: border-box;
            float: left;
            padding-left: 0;
            padding-right: 0;
            text-align: right;
            width: 30%;
        }
        // stylelint-enable
        tr {
            display: block;

            &:first-child {
                th,
                td {
                    padding-top: 2rem;
                }
            }
        }
    }

    @include table-mobile-layout-hook();
}

@mixin table-wrapper-mobile-layout() {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    position: relative; // To hide unnecessary horizontal scrollbar in Safari

    @include table-wrapper-mobile-layout-hook();
}
