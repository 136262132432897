/** Styles form _tables.less of theme blank are moved here.
   * For some tables there is mobile layout applied also for tablet resolutions
   * Variables for typograpy, colors, border etc. are added
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* stylelint-disable no-descending-specificity */
.table-wrapper {
  margin-bottom: 2rem; }
  @media (max-width: 47.99em) {
    .table-wrapper {
      border: none;
      display: block; } }
  @media (min-width: 48em) {
    .table-wrapper.cart .table-wrapper.cart {
      border-top: none; } }
  .table-wrapper.grouped tbody:last-child td {
    border-bottom: none; }

.table {
  border-bottom: 1px solid #dcdcdc;
  max-width: 100%;
  width: 100%;
  background: transparent; }
  .table > caption {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    /* 1 */ }
  .table:not(.totals):not(.table-totals) {
    border: none; }
    .table:not(.totals):not(.table-totals) td {
      border: none;
      padding: 0.8rem 1rem; }
    .table:not(.totals):not(.table-totals) thead {
      border-bottom: 2px solid #dcdcdc; }
      .table:not(.totals):not(.table-totals) thead th {
        font-size: 1.1rem;
        text-transform: uppercase;
        color: #212121; }
    .table:not(.totals):not(.table-totals) tbody td {
      font-size: 1.4rem;
      color: #212121;
      font-weight: 300;
      border-bottom: 1px solid #dcdcdc;
      border-left: none; }
      @media (max-width: 47.99em) {
        .table:not(.totals):not(.table-totals) tbody td.actions {
          border-bottom: 1px solid #dcdcdc; } }
      .table:not(.totals):not(.table-totals) tbody td.actions a + a:before {
        margin: 0 0.7rem 0 0.5rem;
        content: '';
        display: inline-block;
        width: 1px;
        height: 1.4rem;
        border-right: 2px solid #dcdcdc;
        vertical-align: middle; }
      .table:not(.totals):not(.table-totals) tbody td:first-child {
        border-left: none; }
    @media (max-width: 63.99em) {
      .account .table:not(.totals):not(.table-totals) tbody tr:first-child td {
        padding-top: 0; } }
    .table:not(.totals):not(.table-totals) tbody tr:nth-child(even) {
      background: transparent; }
    .table:not(.totals):not(.table-totals) tfoot {
      font-size: 1.4rem; }
      @media (max-width: 47.99em) {
        .table:not(.totals):not(.table-totals) tfoot {
          background: #f0f0f0; } }
      .table:not(.totals):not(.table-totals) tfoot .mark {
        text-align: right; }
      @media (max-width: 47.99em) {
        .table:not(.totals):not(.table-totals) tfoot .amount {
          text-align: right; } }
  .table .price-excluding-tax,
  .table .price-including-tax,
  .table .items-qty,
  .table .product-item-name {
    font-size: 1.4rem;
    line-height: 155%; }

@media (max-width: 47.99em) {
  .table-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    position: relative; }
    .table-wrapper .table:not(.comparison):not(.totals):not(.table-totals) {
      border: none;
      font-size: 1.4rem;
      padding: 0; }
      .table-wrapper .table:not(.comparison):not(.totals):not(.table-totals):last-child {
        padding-bottom: 1.2rem; }
      .table-wrapper .table:not(.comparison):not(.totals):not(.table-totals) thead {
        display: none; }
      .table-wrapper .table:not(.comparison):not(.totals):not(.table-totals) tbody td {
        border: none;
        display: block;
        font-size: 1.4rem;
        padding: 0.2rem; }
        .table-wrapper .table:not(.comparison):not(.totals):not(.table-totals) tbody td:first-child {
          padding-top: 2rem; }
        .table-wrapper .table:not(.comparison):not(.totals):not(.table-totals) tbody td:last-child {
          padding-bottom: 1rem; } }
      @media (max-width: 47.99em) and (max-width: 63.99em) {
        .table-wrapper .table:not(.comparison):not(.totals):not(.table-totals) tbody td:before {
          padding-right: 10px;
          content: attr(data-th) ": ";
          display: inline-block;
          color: #212121;
          font-weight: 700; } }
      @media (max-width: 47.99em) and (max-width: 63.99em) {
        .table-wrapper .table:not(.comparison):not(.totals):not(.table-totals) tbody td.actions {
          border-bottom: 1px solid #dcdcdc; } }
      @media (max-width: 47.99em) and (max-width: 63.99em) {
        .table-wrapper .table:not(.comparison):not(.totals):not(.table-totals) tbody td.actions:before {
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
          white-space: nowrap;
          /* 1 */ } }

@media (max-width: 47.99em) {
      .table-wrapper .table:not(.comparison):not(.totals):not(.table-totals) tfoot {
        display: block; }
        .table-wrapper .table:not(.comparison):not(.totals):not(.table-totals) tfoot th {
          box-sizing: border-box;
          float: left;
          padding-left: 0;
          padding-right: 0;
          text-align: left;
          width: 70%; }
        .table-wrapper .table:not(.comparison):not(.totals):not(.table-totals) tfoot td {
          box-sizing: border-box;
          float: left;
          padding-left: 0;
          padding-right: 0;
          text-align: right;
          width: 30%; }
        .table-wrapper .table:not(.comparison):not(.totals):not(.table-totals) tfoot tr {
          display: block; }
          .table-wrapper .table:not(.comparison):not(.totals):not(.table-totals) tfoot tr:first-child th,
          .table-wrapper .table:not(.comparison):not(.totals):not(.table-totals) tfoot tr:first-child td {
            padding-top: 2rem; }
      .table-wrapper .table:not(.comparison):not(.totals):not(.table-totals).additional-attributes tbody th {
        display: none; }
      .table-wrapper .table:not(.comparison):not(.totals):not(.table-totals).additional-attributes tbody td:last-child {
        border: none;
        padding: 0 0 0.5rem; }
  .data-table-definition-list thead {
    display: none; }
  .data-table-definition-list tbody th {
    padding-bottom: 0; }
  .data-table-definition-list tbody th,
  .data-table-definition-list tbody td {
    display: block;
    padding-left: 0;
    padding-right: 0; } }

.table-wrapper.cart,
.account .table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  position: relative; }

@media (min-width: 48em) and (max-width: 63.99em) {
  .table-wrapper.cart .table,
  .account .table-wrapper .table {
    border: none;
    font-size: 1.4rem;
    padding: 0; }
    .table-wrapper.cart .table:last-child,
    .account .table-wrapper .table:last-child {
      padding-bottom: 1.2rem; }
    .table-wrapper.cart .table thead,
    .account .table-wrapper .table thead {
      display: none; }
    .table-wrapper.cart .table tbody td,
    .account .table-wrapper .table tbody td {
      border: none;
      display: block;
      font-size: 1.4rem;
      padding: 0.2rem; }
      .table-wrapper.cart .table tbody td:first-child,
      .account .table-wrapper .table tbody td:first-child {
        padding-top: 2rem; }
      .table-wrapper.cart .table tbody td:last-child,
      .account .table-wrapper .table tbody td:last-child {
        padding-bottom: 1rem; } }
    @media (min-width: 48em) and (max-width: 63.99em) and (max-width: 63.99em) {
      .table-wrapper.cart .table tbody td:before,
      .account .table-wrapper .table tbody td:before {
        padding-right: 10px;
        content: attr(data-th) ": ";
        display: inline-block;
        color: #212121;
        font-weight: 700; } }
    @media (min-width: 48em) and (max-width: 63.99em) and (max-width: 63.99em) {
      .table-wrapper.cart .table tbody td.actions,
      .account .table-wrapper .table tbody td.actions {
        border-bottom: 1px solid #dcdcdc; } }
    @media (min-width: 48em) and (max-width: 63.99em) and (max-width: 63.99em) {
      .table-wrapper.cart .table tbody td.actions:before,
      .account .table-wrapper .table tbody td.actions:before {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        white-space: nowrap;
        /* 1 */ } }

@media (min-width: 48em) and (max-width: 63.99em) {
    .table-wrapper.cart .table tfoot,
    .account .table-wrapper .table tfoot {
      display: block; }
      .table-wrapper.cart .table tfoot th,
      .account .table-wrapper .table tfoot th {
        box-sizing: border-box;
        float: left;
        padding-left: 0;
        padding-right: 0;
        text-align: left;
        width: 70%; }
      .table-wrapper.cart .table tfoot td,
      .account .table-wrapper .table tfoot td {
        box-sizing: border-box;
        float: left;
        padding-left: 0;
        padding-right: 0;
        text-align: right;
        width: 30%; }
      .table-wrapper.cart .table tfoot tr,
      .account .table-wrapper .table tfoot tr {
        display: block; }
        .table-wrapper.cart .table tfoot tr:first-child th,
        .table-wrapper.cart .table tfoot tr:first-child td,
        .account .table-wrapper .table tfoot tr:first-child th,
        .account .table-wrapper .table tfoot tr:first-child td {
          padding-top: 2rem; } }

/* stylelint-enable */
